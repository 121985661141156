.customModal {
    background: #242424;
    width: 100%;
    height: 100%;
    
  }

.customEventModal {
  background: #242424;
}

.resourceBackground {
  background: #242424;
}